<template>
    <div class="flex justify-center w-full">
        <button type="button" class="inline-flex justify-center w-full m-auto px-12 py-1.5 border border-transparent shadow-sm text-lg font-medium rounded-lg text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" :class="[disabled ? '':'opacity-25']">
            <slot></slot>        
        </button>                        
    </div>
</template>
<script>
export default{
    props:{
        disabled:{
            type:Boolean,
            default:false
        }
    }
}
</script>
